<script setup lang="ts">

import axios from "axios";
import {LogDetailResponse} from "../types";
import {ref, Ref} from "vue";
import {onBeforeRouteUpdate} from "vue-router";

let data: Ref<LogDetailResponse | null> = ref(null);

const props = defineProps<{
    id: number
}>();

const loadLog = async (id: number) => {
    try {
        const logDetail = await axios.get('/api/logs/' + id);
        data.value = logDetail.data as LogDetailResponse;

    } catch (error) {

    }
}

onBeforeRouteUpdate(async (to, from) => {
    if (to.params.id !== from.params.id) {
        await loadLog(Number(to.params.id));
    }
});

loadLog(props.id);

</script>


<template>

    <router-link to="/dashboard/log"  class="mb-4 inline-flex items-center font-medium hover:text-gray-400 text-gray-500 transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-1">
            <path fill-rule="evenodd"  d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clip-rule="evenodd"/>
        </svg>
        {{ $t('log.details.backToLogs') }}
    </router-link>

    <h2 class="text-2xl font-bold dark:text-white mb-8" v-if="data" >
        Log #{{ data.batch }}
    </h2>

    <div class="rounded-lg mt-12" v-if="data">

        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" >
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">{{ $t('log.details.table.headings.product') }}</th>
                        <th scope="col" class="px-2 py-3">Status</th>
                        <th scope="col" class="px-2 py-3">{{ $t('log.details.table.headings.onlinePrice') }}</th>
                        <th scope="col" class="px-2 py-3">{{ $t('log.details.table.headings.updatedPrice') }}</th>
                        <th scope="col" class="px-2 py-3">{{ $t('log.details.table.headings.updatedAt') }}</th>
                        <th scope="col" class="px-2 py-3">{{ $t('log.details.table.headings.notes') }}</th>
                    </tr>
                    </thead>

                    <tbody>

                    <tr v-for="(item, index) in data.logs" class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b">
                        <td class="px-2 py-3 capitalize font-medium text-white">
                            <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: item.catalog_item_id }}" class="flex items-center">

                                <span>{{item.brand_name}} {{item.name}}</span>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 ml-2">
                                  <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
                                  <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
                                </svg>

                            </router-link>
                        </td>

                        <td class="px-2 py-3">
                            <span v-if="item.readable_status === 'Success'" class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{{ $t('log.details.table.statusLabels.success') }}</span>
                            <span v-if="item.readable_status === 'Skipped'" class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{{ $t('log.details.table.statusLabels.skipped') }}</span>
                            <span v-if="item.readable_status === 'Failed'" class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{{ $t('log.details.table.statusLabels.failed') }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize font-medium text-white">
                            <span>€ {{item.old_price}}</span>
                        </td>

                        <td class="px-2 py-3 capitalize font-medium text-white">
                            <div class="flex items-center" v-if="item.new_price">
                                <span>€ {{ item.new_price }}</span>

                                <span v-if="item.percentage_difference" class="ml-3 bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                    <svg v-if="item.percentage_difference < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                    </svg>

                                    <svg v-if="item.percentage_difference > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                      <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                    </svg>

                                    {{ item.percentage_difference }}%
                                </span>
                            </div>
                        </td>

                        <td class="px-2 py-3 capitalize text-xs">
                            <span>{{ item.created_at }}</span>
                        </td>

                        <td class="px-2 py-3 text-xs font-medium">
                            <span>{{ item.readable_reason }}</span>
                        </td>
                    </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
