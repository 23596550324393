<script setup lang="ts">

import {onBeforeRouteUpdate} from "vue-router";
import qs from "qs";
import axios from "axios";
import {ref, Ref} from "vue";
import {Item, Vendor} from "../types";
import {debounce, find} from "lodash";
import {useUserStore} from "../stores/UserStore";
import Grade from "./Grade.vue";
import Flag from "./Flag.vue";

let modelsFilters: string[] = [];
let brandsFilters: string[] = [];
let gradesFilters: string[] = [];
let searchFilter = '';
let page: number = 1;

// Reactive
const vendor: Ref<Vendor|null> = ref(null);
const list: Ref<Array<Item>> = ref([]);
const itemsCount: Ref<number> = ref(0);
const lastUpdate: Ref<string> = ref('');

const showModelsFilters: Ref<boolean> = ref(false);
const showBrandsFilters: Ref<boolean> = ref(false);
const showGradesFilters: Ref<boolean> = ref(false);

const activeModelsFilters: Ref<Array<string>> = ref([]);
const activeBrandsFilters: Ref<Array<string>> = ref([]);
const activeGradesFilters: Ref<Array<string>> = ref([]);
const activeSearchFilter: Ref<string> = ref('');

// Const
const user = useUserStore();


// Props
const props = defineProps<{
    id: number
}>();

const loadVendorInformation = (id: number) => {
    const selectedVendor = find(user.profile?.vendors, {'id': Number(id)});

    if (selectedVendor === undefined || selectedVendor === null) {
        throw new Error('Something went wrong');
    }

    vendor.value = selectedVendor as Vendor;
}

const loadItems = async (id: number) => {
    try {
        list.value = [];
        const query: {
            models?: string[],
            products?: string[],
            page?: number,
            brands?: string[],
            grades?: string[],
            search?: string,
        } = {}

        if (activeModelsFilters.value.length) {
            query.models = activeModelsFilters.value
        }

        if (activeBrandsFilters.value.length) {
            query.brands = activeBrandsFilters.value
        }

        if (activeGradesFilters.value.length) {
            query.grades = activeGradesFilters.value
        }

        if (activeSearchFilter.value) {
            query.search = activeSearchFilter.value;
        }

        query.page = page;

        const queryString = qs.stringify(query);
        const itemsResponse = await axios.get(`/api/competitors/${id}?${queryString}`);

        list.value = itemsResponse.data.items;
        itemsCount.value = itemsResponse.data.items_count;
        lastUpdate.value = itemsResponse.data.last_update;

        updateModelsFilters(itemsResponse.data.models);
        updateBrandsFilters(itemsResponse.data.brands);
        updateGradesFilters(itemsResponse.data.grades);
    } catch (error) {
        console.error(error);
    }
}

const loadMore = async (event: Event) => {
    await loadMoreItems(props.id);
}

const onModelsFilterChange = async (event: Event) => {
    page = 1;
    await loadItems(props.id);
}

const onProductsFilterChange = async (event: Event) => {
    page = 1;
    await loadItems(props.id);
}

const onBrandsFilterChange = async (event: Event) => {
    activeModelsFilters.value = [];

    page = 1;
    await loadItems(props.id);
}

const onGradesFilterChange = async (event: Event) => {
    page = 1;
    await loadItems(props.id);
}

const onSearchInputChange = debounce(onProductsFilterChange, 500);

const updateModelsFilters = (models: string[]) => {
    modelsFilters = Array.from(new Set(models));
}

const updateBrandsFilters = (brands: string[]) => {
    brandsFilters = Array.from(new Set(brands));
}

const updateGradesFilters = (grades: string[]) => {
    gradesFilters = Array.from(new Set(grades));
}

const loadMoreItems = async (id: number) => {
    try {
        page ++;

        const query: {
            models?: string[],
            products?: string[],
            page?: number,
            brands?: string[],
            grades?: string[],
            search?: string,
        } = {}

        if (activeModelsFilters.value.length) {
            query.models = activeModelsFilters.value
        }

        if (activeBrandsFilters.value.length) {
            query.brands = activeBrandsFilters.value
        }

        if (activeGradesFilters.value.length) {
            query.grades = activeGradesFilters.value
        }

        if (activeSearchFilter.value) {
            query.search = activeSearchFilter.value;
        }

        query.page = page;

        const queryString = qs.stringify(query);
        const itemsResponse = await axios.get(`/api/competitors/${id}?${queryString}`);

        list.value = list.value.concat(itemsResponse.data.items);

    } catch (error) {
        console.error(error);
    }
}

const closeFilters = () => {
    showModelsFilters.value = false;
    showBrandsFilters.value = false;
    showGradesFilters.value = false;
}

const resetFilters = () => {
    activeModelsFilters.value = [];
    activeBrandsFilters.value = [];
    activeGradesFilters.value = [];
    activeSearchFilter.value = '';
    modelsFilters = [];
    brandsFilters = [];
    gradesFilters = [];
    searchFilter = '';
    page = 1;
}

const onResetFiltersBtnClick = async (event: Event) => {
    resetFilters();
    await loadItems(props.id);
}

onBeforeRouteUpdate(async (to, from) => {
    if (to.params.id !== from.params.id) {
        resetFilters();
        loadVendorInformation(Number(to.params.id));
        await loadItems(Number(to.params.id));
    }
});

loadVendorInformation(props.id);
loadItems(props.id);

</script>

<template>
    <h2 class="text-4xl mb-4 font-bold text-white flex items-center">
        <span class="block mr-4">{{ vendor?.name }}</span>

        <Flag :country="vendor?.country || ''" :visible="true" :size="25" />
    </h2>

    <div class="mt-12 shadow-md">

        <!-- Header -->
        <div class="relative bg-gray-800 rounded-t-lg">

            <div class="flex justify-between items-end mx-4 px-4 py-8">
<!--                <div class="space-y-3">-->
                    <div class="flex flex-row space-x-12 text-white items-center">
                        <div>
                            <dt class="mb-1 text-3xl font-extrabold">{{ itemsCount }}</dt>
                            <dd class="text-xs font-bold text-gray-400 uppercase">{{ vendor?.category.name }}</dd>
                        </div>
                    </div>
<!--                </div>-->

                <div>
                    <span class="text-gray-500 text-xs">{{ $t('common.updatedAt', {updatedAt: lastUpdate}) }}</span>
                </div>
            </div>


            <!-- Filters & Buttons -->
            <div class="flex flex-row justify-between items-center p-4">

                <!-- Filters -->
                <div class="flex flex-row space-x-2 items-center">

                    <!-- Brand Filter -->
                    <div v-if="brandsFilters.length">

                        <div class="relative">
                            <button @click="showBrandsFilters = true" v-click-outside="closeFilters" class="text-white outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-blue-600 hover:bg-blue-700" type="button">
                                {{ $t('common.filters.byBrand') }}

                                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            <transition
                                enter-from-class="-translate-y-6 opacity-0 scale-75"
                                enter-to-class="translate-y-0 opacity-100 scale-100"
                                leave-from-class="translate-y-0 opacity-100 scale-100"
                                leave-to-class="-translate-y-6 opacity-0 scale-75" >
                                <div v-if="showBrandsFilters" class="absolute z-10 top-12 -left-4 w-96 p-3 rounded-lg shadow bg-gray-700 shadow-lg">
                                    <h6 class="mb-3 text-xs font-medium text-white">{{ $t('common.brands') }}</h6>

                                    <ul class="space-y-2 text-sm">
                                        <li class="flex items-center" v-for="brandFilter in brandsFilters">
                                            <input @change="onBrandsFilterChange" v-model="activeBrandsFilters" :id="brandFilter" type="checkbox" :value="brandFilter" class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                            <label :for="brandFilter" class="ml-2 text-sm font-medium text-gray-100 truncate">
                                                {{ brandFilter }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <!-- Model Filter -->
                    <div v-if="modelsFilters.length">
                        <div class="relative">
                            <button @click="showModelsFilters = true" v-click-outside="closeFilters" class="text-white outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-blue-600 hover:bg-blue-700" type="button">
                                {{ $t('common.filters.byModels') }}

                                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            <transition
                                enter-from-class="-translate-y-6 opacity-0 scale-75"
                                enter-to-class="translate-y-0 opacity-100 scale-100"
                                leave-from-class="translate-y-0 opacity-100 scale-100"
                                leave-to-class="-translate-y-6 opacity-0 scale-75" >
                                <div v-if="showModelsFilters" class="absolute z-10 top-12 -left-4 w-96 p-3 rounded-lg shadow bg-gray-700 shadow-lg">
                                    <h6 class="mb-3 text-xs font-medium text-white">{{ $t('common.models') }}</h6>

                                    <ul class="space-y-2 text-sm">
                                        <li class="flex items-center" v-for="modelsFilter in modelsFilters">
                                            <input @change="onModelsFilterChange" v-model="activeModelsFilters" :id="modelsFilter" type="checkbox" :value="modelsFilter" class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />

                                            <label :for="modelsFilter" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100 truncate ...">
                                                {{ modelsFilter }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <!-- Grades Filter -->
                    <div v-if="gradesFilters.length">
                        <div class="relative">
                            <button @click="showGradesFilters = true" v-click-outside="closeFilters" class="text-white outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-blue-600 hover:bg-blue-700" type="button">
                                {{ $t('common.filters.byGrade') }}

                                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            <transition
                                enter-from-class="-translate-y-6 opacity-0 scale-75"
                                enter-to-class="translate-y-0 opacity-100 scale-100"
                                leave-from-class="translate-y-0 opacity-100 scale-100"
                                leave-to-class="-translate-y-6 opacity-0 scale-75" >
                                <div v-if="showGradesFilters" class="absolute z-10 top-12 -left-4 w-96 p-3 rounded-lg shadow bg-gray-700 shadow-lg">
                                    <h6 class="mb-3 text-xs font-medium text-white">{{ $t('common.grades') }}</h6>

                                    <ul class="space-y-2 text-sm">
                                        <li class="flex items-center" v-for="gradeFilter in gradesFilters">
                                            <input @change="onGradesFilterChange" v-model="activeGradesFilters" :id="gradeFilter" type="checkbox" :value="gradeFilter" class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />

                                            <label :for="gradeFilter" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100 truncate ...">
                                                {{ gradeFilter }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <!-- Search filter -->
                    <div>
                        <input v-model="activeSearchFilter" @input="onSearchInputChange" type="text" class="border border-gray-500 bg-gray-700 focus:outline-none focus:border-blue-500 text-white px-4 py-2.5 text-sm font-medium rounded-lg" :placeholder="$t('common.filters.search')" />
                    </div>

                    <a href="#" class="inline-block pl-2 text-xs text-gray-300 underline" @click.prevent="onResetFiltersBtnClick">{{ $t('common.filters.resetBtn') }}</a>

                </div>

            </div>

        </div>

        <!-- Table -->
        <div class="relative overflow-x-auto bg-gray-800">
            <table class="w-full text-sm text-left text-gray-400">
                <thead class="text-xs uppercase bg-gray-700 text-gray-400">
                <tr>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.name') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.catalogItems') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.grade') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.price') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.storage') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.color') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.battery') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.reseller') }}</th>
                    <th scope="col" class="px-2 py-3">{{ $t('competitor.table.headings.details') }}</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(item, index) in list" class="border-gray-600 hover:bg-gray-700 border-b">

                    <td class="px-2 py-3 font-medium text-white">
                        <div class="max-w-[380px]">
                            <span class="block truncate">{{ item.name }}</span>
                        </div>
                    </td>

                    <td class="px-2 font-normal whitespace-nowrap text-white">
                        <div class="py-2">
                            <div v-for="(catalogItem, index) in item.catalog_items" class="flex items-center py-1 text-xs text-gray-600">
                                <p class="mr-4 w-32">EAN: <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: catalogItem.id }}" class="text-white hover:underline">{{catalogItem.ean}}</router-link></p>
                                <p class="mr-4">ID: <router-link :to="{ name: 'dashboard.catalog.detail', params: { id: catalogItem.id }}" class="text-white hover:underline">{{catalogItem.external_id}}</router-link></p>
                            </div>
                        </div>
                    </td>

                    <td class="px-2 py-3">
                        <Grade :grade="item.grade" />
                    </td>

                    <td class="px-2 py-3 font-medium text-white">
                        {{ item.currency_symbol }} {{ item.price }}
                    </td>

                    <td class="px-2 py-3 text-xs font-medium">
                        {{ item.storage }}
                    </td>

                    <td class="px-2 py-3 capitalize text-xs font-medium">
                        {{ item.color ?? '-' }}
                    </td>

                    <td class="px-2 py-3 capitalize text-xs font-medium">
                        {{ item.battery ?? 'Standard' }}
                    </td>

                    <td class="px-2 py-3 text-xs text-gray-300">
                        {{ item.seller_name }}
                    </td>

                    <td class="px-2 py-3">
                        <router-link :to="{ name: 'dashboard.competitor.items.detail', params: { id: props.id, itemId: item.id }}" class="font-medium text-blue-500 hover:underline">{{ $t('competitor.table.more') }}</router-link>
                    </td>

                </tr>


                </tbody>
            </table>
        </div>

        <!-- Load more -->
        <div class="relative overflow-hidden rounded-b-lg bg-gray-800">
            <nav class="flex flex-row items-center justify-between p-4" aria-label="Table navigation">
                <div class="text-sm">
                    <p class="font-semibold text-white">
                        {{ list.length }} <span class="font-normal text-gray-400">{{ $t('common.of') }}</span> {{ itemsCount }}
                    </p>
                </div>
                <button type="button" class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800" @click="loadMore" v-if="list.length < itemsCount">
                    Load more
                </button>
            </nav>
        </div>
    </div>
</template>
