<script setup lang="ts">

import {Ref, ref} from "vue";
import {AlertMessage, BuybackStrategy, BuybackStrategyResponse, StrategyResponse} from "../types";
import {useUserStore} from "../stores/UserStore";
import Alert from "./Alert.vue";
import BuybackStrategyCard from "./BuybackStrategyCard.vue";
import {i18n} from "../main";

const user = useUserStore();
const strategies: Ref<Array<BuybackStrategy> | null> = ref(user.buybackStrategies);
const alertMessage: Ref<AlertMessage | null> = ref(null);

const onSubmit = (event: Event) => {
    alertMessage.value = null;
}

const onSuccess = (strategies: BuybackStrategyResponse) => {
    alertMessage.value = {
        type: 'success',
        message: i18n.global.t('strategies.messages.strategyUpdated'),
    }

    user.updateBuybackStrategies(strategies);
}

const onError = (message: string) => {
    alertMessage.value = {
        type: 'error',
        message: message,
    }
}

</script>

<template>
    <h2 class="text-4xl font-bold dark:text-white">{{ $t('strategies.buyback.title') }}</h2>
    <p class="my-4 text-sm text-gray-500" v-html='$t("strategies.buyback.subTitle")' />

    <BuybackStrategyCard v-for="strategy in user.buybackStrategies"
                         :key="strategy.id"
                         :strategy="strategy"
                         @success="onSuccess"
                         @error="onError"
                         @submit="onSubmit"
    />

    <Alert :message="alertMessage" />
</template>
